import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ImageBlock from './ImageBlock'
import TextBlock from './TextBlock'
import blob from '../images/blob.svg'
import './textImageDark.scss'
import Wave from '../images/wave.png'
import ServiceCTA from './../../ServiceLanding/CTA/ServiceCTA';
const TextImageDark = ({ctaTitle}) => {
    return (
        <section className="text-image-dark relative bg-[#1D2333] z-10 mb-5 lg:mb-[232px] py-10 lg:py-24 ">
            <div className="container">
                <div className="grid grid-cols-1 justify-items-center md:grid-cols-2 gap-y-10 lg:gap-x-[106px]">
                    <div className="lg:pt-11">
                        <TextBlock
                            title="Battle Tested SSGs"
                            titleColor="text-white"
                            titleMaxW="max-w-[419px]"
                        >
                            <div className="mt-5">
                                <p className="block mx-auto max-w-[419px] text-white mb-3 md:mb-8 lg:mb-[38px] leading-6 md:text-sm lg:text-lg md:leading-7 lg:leading-9 tracking-[-0.025rem]">
                                    Jamstack approach enables you to run a fully
                                    dynamic site while the actual assets are
                                    pre-rendered static files deployed on CDN.
                                    For you, it means faster, more secure, more
                                    reliable websites with better SEO ranking
                                    capabilities.
                                </p>
                                <p className="block mx-auto max-w-[419px] text-white mb-3 md:mb-8 lg:mb-[38px] leading-6 md:text-sm lg:text-lg md:leading-7 lg:leading-9 tracking-[-0.025rem]">
                                    Jamstack approach enables you to run a fully
                                    dynamic site while the actual assets are
                                    pre-rendered static files deployed on CDN.
                                    For you, it means faster, more secure, more
                                    reliable websites with better SEO ranking
                                    capabilities.
                                </p>
                            </div>
                        </TextBlock>
                    </div>
                    <ImageBlock bgImage={blob}>
                        <StaticImage
                            alt="coding"
                            className="mt-[40px] md:ml-[40px] w-full h-full"
                            src="../images/coding.png"
                        />
                    </ImageBlock>
                </div>
            </div>
            <div>
                <div className="mt-10 md:mt-14 lg:mt-[181px]">
                    <ServiceCTA title={ctaTitle} />
                </div>
            </div>

            <div className="wave absolute w-full bottom-0 translate-y-[99%]">
                <img src={Wave} alt="wave image" />
            </div>
        </section>
    )
}

export default TextImageDark
