import React, { useState, useRef, useEffect } from 'react'
import { MorphReplace } from 'react-svg-morph'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Piece1 from '@images/serviceHero/digital/digital-piece-1.svg'
import Piece2 from '@images/serviceHero/digital/digital-piece-2.svg'
import Piece3 from '@images/serviceHero/digital/digital-piece-3.svg'

const DigitalIcon = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece-1'),
            {
                top: '5%',
                left: '5%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 256px)',
                left: 'calc(35% - 104px)',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-2'),
            {
                top: '5%',
                left: '40%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 250px)',
                left: 'calc(35% + 49px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-3'),
            {
                top: '15%',
                left: '60%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 170px)',
                left: '35%',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    return (
        <div
            ref={ref}
            className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]"
        >
            <div className="absolute top-[10%] wrap-pice"></div>
            <div className="piece-1 absolute z-[2] w-[calc(3721px*0.04)]">
                <img alt="digital-piece" src={Piece1} />
            </div>
            <div className="piece-2 absolute z-[2] w-[calc(2500px*0.04)]">
                <img alt="digital-piece" src={Piece2} />
            </div>
            <div className="piece-3 absolute z-[2] w-[calc(4436px*0.04)]">
                <img alt="digital-piece" src={Piece3} />
            </div>
        </div>
    )
}

export default DigitalIcon
