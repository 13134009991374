import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ImageBlock from './ImageBlock'
import TextBlock from './TextBlock'
import hustle from '../images/hustle-vector.svg'
import TiltAnimationWrapper from '../../TiltAnimation/TiltAnimation'

const TextImageLight = ({ title, description }) => {
    return (
        <section className="py-10 lg:py-20">
            <div className="container">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 lg:gap-x-[106px]">
                    <ImageBlock bgImage={hustle}>
                        <TiltAnimationWrapper>
                            <StaticImage
                                alt="hustle-tablet"
                                src="../images/hustle-tablet.svg"
                            />
                        </TiltAnimationWrapper>
                    </ImageBlock>
                    <TextBlock
                        title={title}
                        titleColor="text-black"
                        titleMaxW="max-w-[419px]"
                    >
                        <p className="block mx-auto max-w-[419px] text-[#1D2333]/[0.8] mb-3 md:mb-8 lg:mb-[38px] leading-6 md:text-sm lg:text-lg md:leading-7 lg:leading-9 tracking-[-0.025rem]">
                            {description}
                        </p>
                    </TextBlock>
                </div>
            </div>
        </section>
    )
}

export default TextImageLight
