import React from 'react'
import Hero from '../Hero'
import DigitalIcon from './DigitalIcon'

const HeroDigitalTransform = ({
    heroTextTitle,
    heroTextSmallText,
    ctaTitle,
    heroData
}) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <DigitalIcon />
        </Hero>
    )
}

export default HeroDigitalTransform
